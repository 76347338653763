<template>
  <div class="quickstart">
    <!--Header-->
    <div
      class="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="text-white m-0 flex-grow-1 mr-3">
        {{ $t("HOME.QUICKSTART.TITLE") }}
      </h4>
    </div>

    <!-- CONTENT -->
    <div class="card card-custom card-stretch gutter-b w-100">
      <div class="card-body">
        <v-expansion-panels class="mb-6">
          <!-- PROFILE -->
          <ItemExpansionPanel
            :title="$t('HOME.PROFILE.TITLE')"
            :description="$t('HOME.PROFILE.DESCRIPTION')"
            :textBtn="$t('HOME.PROFILE.BTN')"
            :check="infoProfile"
            path="settings/profile"
            v-on:skip="openModal(0)"
            v-if="steps[0]"
          />

          <!-- COMPANY -->
          <ItemExpansionPanel
            :title="$t('HOME.COMPANY.TITLE')"
            :description="$t('HOME.COMPANY.DESCRIPTION')"
            :textBtn="$t('HOME.COMPANY.BTN')"
            :check="infoCompany"
            path="settings/company"
            :isSkip="false"
            v-if="itsCompany && generalScope"
          />

          <!-- CAMPUS -->
          <ItemExpansionPanel
            :title="$t('HOME.CAMPUS.TITLE')"
            :description="$t('HOME.CAMPUS.DESCRIPTION')"
            :textBtn="$t('HOME.CAMPUS.BTN')"
            :check="infoCampus"
            path="campus"
            :isSkip="false"
            v-if="itsSchool && itsCompany && generalScope"
          />

          <!-- LODGING SCHOOL -->
          <ItemExpansionPanel
            :title="$t('HOME.LODGING.TITLE')"
            :description="$t('HOME.LODGING.DESCRIPTION2')"
            :textBtn="$t('HOME.LODGING.BTN')"
            :check="infoLodging"
            path="lodging"
            v-on:skip="openModal(10)"
            v-if="steps[10] && itsSchool && generalScope"
          />

          <!-- LODGING -->
          <ItemExpansionPanel
            :title="$t('HOME.LODGING.TITLE')"
            :description="$t('HOME.LODGING.DESCRIPTION')"
            :textBtn="$t('HOME.LODGING.BTN')"
            :check="infoLodging"
            path="lodging"
            :isSkip="false"
            v-if="itsLodgingAndHomestay && itsCompany && generalScope"
          />

          <!-- LODGING HOMETUITION -->
          <ItemExpansionPanel
            :title="$t('HOME.LODGING.TITLE')"
            :description="$t('HOME.LODGING.DESCRIPTION1')"
            :textBtn="$t('HOME.LODGING.BTN')"
            :check="infoLodging"
            path="lodging"
            :isSkip="false"
            v-if="itsHomeTuition"
          />

          <!-- TEAM -->
          <ItemExpansionPanel
            :title="$t('HOME.TEAM.TITLE')"
            :description="descriptionTeam"
            :textBtn="$t('HOME.TEAM.BTN')"
            :check="infoTeam"
            path="settings/teams"
            v-on:skip="openModal(1)"
            v-if="
              steps[1] &&
                itsSchoolAndLodging &&
                infoCampus &&
                itsCompany &&
                generalScope
            "
          />

          <!-- PRICING -->
          <ItemExpansionPanel
            :title="$t('HOME.PRICING.TITLE')"
            :description="$t('HOME.PRICING.DESCRIPTION')"
            :textBtn="$t('HOME.PRICING.BTN')"
            :check="infoPricing"
            path="configure/pricing"
            v-on:skip="openModal(2)"
            v-if="steps[2] && infoCampus && productScope"
          />

          <!-- FEES -->
          <ItemExpansionPanel
            :title="$t('HOME.FEES.TITLE')"
            :description="$t('HOME.FEES.DESCRIPTION')"
            :textBtn="$t('HOME.FEES.BTN')"
            :check="infoFees"
            path="configure/fees"
            v-on:skip="openModal(3)"
            v-if="steps[3] && infoCampus && productScope"
          />

          <!-- TIMETABLES -->
          <ItemExpansionPanel
            :title="$t('HOME.TIMETABLES.TITLE')"
            :description="$t('HOME.TIMETABLES.DESCRIPTION')"
            :textBtn="$t('HOME.TIMETABLES.BTN')"
            :check="infoTimetables"
            path="configure/timetables"
            v-on:skip="openModal(4)"
            v-if="
              steps[4] && itsSchoolAndHomeTuition && infoCampus && productScope
            "
          />

          <!-- CALENDAR -->
          <ItemExpansionPanel
            :title="$t('HOME.CALENDAR.TITLE')"
            :description="descriptionCalendar"
            :textBtn="$t('HOME.CALENDAR.BTN')"
            :check="infoCalendar"
            path="configure/calendar"
            :isSkip="false"
            v-if="infoCampus && productScope"
          />

          <!-- MARKETS -->
          <ItemExpansionPanel
            :title="$t('HOME.MARKETS.TITLE')"
            :description="$t('HOME.MARKETS.DESCRIPTION')"
            :textBtn="$t('HOME.MARKETS.BTN')"
            :check="infoMarkets"
            path="configure/markets"
            v-on:skip="openModal(6)"
            v-if="steps[6] && infoCampus && itsCompany && generalScope"
          />

          <!-- COURSES -->
          <ItemExpansionPanel
            :title="$t('HOME.COURSES.TITLE')"
            :description="descriptionCourses"
            :textBtn="$t('HOME.COURSES.BTN')"
            :check="infoCourses"
            path="products/course"
            :isSkip="false"
            v-if="itsSchoolAndHomeTuition && infoCampus && productScope"
          />

          <!-- ROOMS -->
          <ItemExpansionPanel
            :title="$t('HOME.ROOMS.TITLE')"
            :description="descriptionRooms"
            :textBtn="$t('HOME.ROOMS.BTN')"
            :check="infoRooms"
            path="products/room"
            :isSkip="false"
            v-if="itsLodgingAndHomestay && infoCampus && productScope"
          />

          <!-- SERVICES -->
          <ItemExpansionPanel
            :title="$t('HOME.SERVICES.TITLE')"
            :description="descriptionServices"
            :textBtn="$t('HOME.SERVICES.BTN')"
            :check="infoServices"
            path="products/service"
            v-on:skip="openModal(7)"
            v-if="steps[7] && infoCampus && productScope"
          />

          <!-- PROMOTIONS -->
          <ItemExpansionPanel
            :title="$t('HOME.PROMOTIONS.TITLE')"
            :description="$t('HOME.PROMOTIONS.DESCRIPTION')"
            :textBtn="$t('HOME.PROMOTIONS.BTN')"
            :check="infoPromotions"
            path="promotions"
            v-on:skip="openModal(8)"
            v-if="steps[8] && infoCampus && productScope"
          />

          <!-- PACKAGE -->
          <ItemExpansionPanel
            :title="$t('HOME.PACKAGE.TITLE')"
            :description="$t('HOME.PACKAGE.DESCRIPTION')"
            :textBtn="$t('HOME.PACKAGE.BTN')"
            :check="infoPackages"
            path="packages"
            v-on:skip="openModal(9)"
            v-if="steps[9] && infoCampus && productScope"
          />

          <!-- COMMENTS -->
          <ItemExpansionPanel
            :title="$t('HOME.COMMENTS.TITLE')"
            :description="$t('HOME.COMMENTS.DESCRIPTION')"
            :textBtn="$t('HOME.COMMENTS.BTN')"
            :check="infoComments"
            path="configure/reviews"
            :comments="true"
            :isSkip="false"
            v-if="infoCampus && generalScope"
          />
        </v-expansion-panels>
      </div>
    </div>
    <!-- MODAL SKIP -->
    <v-dialog v-model="isShowDialogSkip" max-width="500">
      <v-card class="pt-3">
        <v-card-title class="headline">
          {{ $t("HOME.QUICKSTART.TITLE2") }}
        </v-card-title>
        <v-card-text>
          <h3>
            {{ $t("HOME.QUICKSTART.DESCRIPTION") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="cancel">
            {{ $t("HOME.QUICKSTART.NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="skipStep">
            {{ $t("HOME.QUICKSTART.YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ItemExpansionPanel from "./components/ItemExpansionPanel";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import { GET_ALL_ACCOMMODATION } from "@/core/services/store/general/accommodation.module";
import { GET_ALL_PRODUCT_PRICINGS } from "@/core/services/store/configure/product_pricing.module";
import { GET_PRODUCT_TIMETABLE_TEMPLATES } from "@/core/services/store/general/time_table.module";
import { GET_ALL_MARKETS } from "@/core/services/store/market/market.module";
import { GET_ALL_USERS } from "@/core/services/store/auth.module";
import { GET_ALL_PRODUCT_PACKAGES } from "@/core/services/store/packages/product_package.module";
import { mapGetters, mapActions } from "vuex";

const LEGAL_PERSON = 1;
const SCHOOL = 1;
const LODGING = 2;
const HOMESTAY = 3;
const HOMETUITION = 4;
export default {
  components: {
    ItemExpansionPanel,
  },
  data() {
    return {
      skipItem: null,
      steps: [
        true, // Profile
        true, // Team
        true, // PRICING
        true, // FEES
        true, // TIMETABLES
        true, // CALENDAR
        true, // MARKETS
        true, // SERVICES
        true, // PROMOTIONS
        true, // PACKAGE
        true, // LODGING
      ],
      isShowDialogSkip: false,
      descriptionTeam: null,
      descriptionCalendar: null,
      descriptionCourses: null,
      descriptionRooms: null,
      descriptionServices: null,
    };
  },
  methods: {
    ...mapActions(["getListFees", "getAddons", "getRooms", "getCourses"]),
    /**
     * Open modal skip
     * @method openModal
     */
    openModal(item) {
      this.isShowDialogSkip = true;
      this.skipItem = Number(item);
    },

    /**
     *cancel skip
     * @method cancel
     */
    cancel() {
      this.isShowDialogSkip = false;
      this.skipItem = null;
    },

    /**
     * Show/Hide Step
     * @method skipStep
     */
    skipStep() {
      this.steps[this.skipItem] = false;
      this.isShowDialogSkip = false;
      // Update Status
      const { user } = this.currentUser;

      const data = {
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        companyPosition: user.companyPosition,
        id: user.id,
        quickstart: this.steps.join(),
      };

      this.$store.dispatch(UPDATE_USER, data);
    },
  },
  created() {
    this.$store.dispatch(GET_ALL_ACCOMMODATION);
    this.$store.dispatch(GET_ALL_USERS);
    if (this.campus && this.campus.campusId) {
      this.$store.dispatch(GET_ALL_PRODUCT_PRICINGS);
      this.$store.dispatch(GET_PRODUCT_TIMETABLE_TEMPLATES, {});
      this.$store.dispatch(GET_ALL_MARKETS);
      this.$store.dispatch(GET_ALL_PRODUCT_PACKAGES);
      this.getListFees();
      this.getCourses();
      this.getRooms();
      this.getAddons();
    }
    // Update Status
    const { user } = this.currentUser;
    if (user.quickstart !== null) {
      const quickstartData = user.quickstart.split(",");
      quickstartData.forEach((element, index) => {
        this.steps[index] = element === "true";
      });
    }
  },
  mounted() {
    // SET DESCRIPTION TEAM
    this.descriptionTeam = this.itsSchool
      ? this.$t("HOME.TEAM.DESCRIPTION1")
      : this.$t("HOME.TEAM.DESCRIPTION2");

    // SET DESCRIPTION CALENDAR
    if (this.itsSchoolAndLodging) {
      this.descriptionCalendar = this.itsSchool
        ? this.$t("HOME.CALENDAR.DESCRIPTION1")
        : this.$t("HOME.CALENDAR.DESCRIPTION2");
    } else {
      this.descriptionCalendar = this.itsHomeStay
        ? this.$t("HOME.CALENDAR.DESCRIPTION3")
        : this.$t("HOME.CALENDAR.DESCRIPTION4");
    }

    // SET DESCRIPTION COURSES
    this.descriptionCourses = this.itsSchool
      ? this.$t("HOME.COURSES.DESCRIPTION1")
      : this.$t("HOME.COURSES.DESCRIPTION2");

    // SET DESCRIPTION ROOMS
    this.descriptionRooms = this.itsHomeStay
      ? this.$t("HOME.ROOMS.DESCRIPTION2")
      : this.$t("HOME.ROOMS.DESCRIPTION1");

    // SET DESCRIPTION SERVICES
    if (this.itsSchool) {
      this.descriptionServices = this.$t("HOME.SERVICES.DESCRIPTION1");
    } else {
      this.descriptionServices = this.itsHomeTuition
        ? this.$t("HOME.SERVICES.DESCRIPTION3")
        : this.$t("HOME.SERVICES.DESCRIPTION2");
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "company",
      "campus",
      "accommodations",
      "userList",
      "productPricings",
      "getFees",
      "timeTables",
      "productStartDateTemplates",
      "markets",
      "courses",
      "rooms",
      "addons",
      "promotions",
      "productPackages",
      "reviews",
      "currentUser",
      "campuses",
    ]),
    backgroundImage() {
      return `${process.env.VUE_APP_CDN_PATH}images/background/quick_start.jpg`;
    },
    infoProfile() {
      const { user } = this.currentUser;
      if (
        user.companyPosition !== null &&
        user.email !== null &&
        user.lastname !== null &&
        user.name !== null
      ) {
        return true;
      }
      return false;
    },
    infoCompany() {
      let flag = false;
      if (this.company.bussinessPrivate === LEGAL_PERSON) {
        if (
          this.company.commercial_name !== null &&
          this.company.legal_name !== null
        ) {
          flag = true;
        }
      } else {
        if (
          this.company.first_name !== null &&
          this.company.last_name !== null
        ) {
          flag = true;
        }
      }

      if (
        this.company.vat !== null &&
        this.company.bussinessPrivate !== null &&
        this.company.address !== null &&
        this.company.phone !== null &&
        this.company.websiteUrl !== null &&
        flag
      ) {
        return true;
      }
      return false;
    },
    infoCampus() {
      return this.campuses?.length > 0;
    },
    infoLodging() {
      return this.accommodations?.length > 0;
    },
    infoTeam() {
      return this.userList?.length > 0;
    },
    infoPricing() {
      return this.productPricings?.length > 0;
    },
    infoFees() {
      return this.getFees?.length > 0;
    },
    infoTimetables() {
      return this.timeTables?.length > 0;
    },
    infoCalendar() {
      if (this.campus.campus_holidays) {
        return this.campus.campus_holidays.length > 0;
      }
      return false;
    },
    infoMarkets() {
      return this.markets?.length > 0;
    },
    infoCourses() {
      return this.courses?.length > 0;
    },
    infoRooms() {
      return this.rooms?.length > 0;
    },
    infoServices() {
      return this.addons?.length > 0;
    },
    infoPromotions() {
      return this.promotions?.length > 0;
    },
    infoPackages() {
      return this.productPackages?.length > 0;
    },
    infoComments() {
      return this.reviews?.length > 0;
    },
    itsSchool() {
      const { companyTypeId } = this.company;
      return companyTypeId === SCHOOL;
    },
    itsHomeTuition() {
      const { companyTypeId } = this.company;
      return companyTypeId === HOMETUITION;
    },
    itsHomeStay() {
      const { companyTypeId } = this.company;
      return companyTypeId === HOMESTAY;
    },
    itsSchoolAndHomeTuition() {
      const { companyTypeId } = this.company;
      return companyTypeId === SCHOOL || companyTypeId === HOMETUITION;
    },
    itsLodgingAndHomestay() {
      const { companyTypeId } = this.company;
      return companyTypeId === LODGING || companyTypeId === HOMESTAY;
    },
    itsSchoolAndLodging() {
      const { companyTypeId } = this.company;
      return companyTypeId === SCHOOL || companyTypeId === LODGING;
    },
    itsHomeStayAndHomeTuition() {
      const { companyTypeId } = this.company;
      return companyTypeId === HOMESTAY || companyTypeId === HOMETUITION;
    },
    itsCompany() {
      if (this.itsHomeStayAndHomeTuition) {
        return true;
      } else {
        return this.currentUser.user.companyId;
      }
    },
    generalScope() {
      if (this.itsHomeStayAndHomeTuition) {
        return true;
      } else {
        const { user_roles } = this.currentUser.user;
        const general = user_roles.find(
          rol => rol.user_role_type.scope === "general"
        );
        return general;
      }
    },
    productScope() {
      if (this.itsHomeStayAndHomeTuition) {
        return true;
      } else {
        const { user_roles } = this.currentUser.user;
        const products = user_roles.find(
          rol => rol.user_role_type.scope === "products"
        );
        return products;
      }
    },
  },
};
</script>

<style lang="scss">
.quickstart {
  .icon-muted {
    svg {
      font-size: 18px;
      color: #9e9e9e;
    }
  }

  .icon-success {
    svg {
      color: #22ab3f !important;
    }
  }

  .v-chip.v-size--default {
    border-radius: 25px !important;
    font-size: 10px !important;
    height: 20px !important;
    float: right !important;
  }
}
</style>
