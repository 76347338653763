<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{ $t("HOME.APPLICATIONS.TITLE") }}
        </span>
      </h3>
    </div>

    <!-- CONTENT -->
    <div class="card-body pt-3 pb-0 max-height-725 scroll">
      <!--begin::Table-->
      <div class="table-responsive">
        <Table
          status="1"
          :newHeaders="true"
          :headersStudent="headers_students_table"
          :headersProduct="headers_product_table"
        />
      </div>
      <!--end::Table-->
    </div>
  </div>
</template>

<script>
import Table from "@/view/pages/application/components/table/index";

export default {
  components: {
    Table,
  },
  data() {
    return {
      headers_students_table: [
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM2"),
          value: "student",
          sortable: false,
        },
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM5"),
          value: "state_icon",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM6"),
          value: "reserve_date",
          sortable: false,
        },
      ],
      headers_product_table: [
        {
          text: this.$t("APPLICATION.TABLE.PRODUCTS_HEADER.ITEM1"),
          value: "Product.name",
          sortable: false,
        },
        {
          text: this.$t("APPLICATION.TABLE.PRODUCTS_HEADER.ITEM3"),
          value: "Campus.name",
          sortable: false,
        },
      ],
    };
  },
  computed: {},
};
</script>

<style scoped>
.max-height-725 {
  max-height: 537px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 0.42rem !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e4e6ef;
  opacity: 1;
  border-radius: 0.42rem !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
