<template>
  <div class="profile-home">
    <!-- CONTENT -->
    <div class="card card-custom card-stretch gutter-b w-100">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
          </div>
          <div class="d-flex flex-column">
            <strong>
              <h4>
                {{ currentUser.user.name }} {{ currentUser.user.lastname }}
              </h4>
            </strong>
            <span class="navi-text text-muted">
              {{ currentUser.user.companyPosition }}
            </span>
            <strong class="mt-5">
              <h4>
                {{ campus.name }}
              </h4>
            </strong>
          </div>
        </div>
        <v-divider />
        <div class="d-flex flex-wrap">
          <!-- EMAIL -->
          <div class="w-50 mt-2">
            {{ $t("HOME.PROFILE.EMAIL") }}
          </div>
          <div class="w-50 text-right text-muted mt-2">
            {{ currentUser.user.email }}
          </div>

          <!-- PHONE -->
          <div class="w-50 mt-2">
            {{ $t("HOME.PROFILE.PHONE") }}
          </div>
          <div class="w-50 text-right text-muted mt-2">
            {{ company.phone }}
          </div>

          <!-- COMPANY NAME -->
          <div class="w-50 mt-2">
            {{ $t("HOME.PROFILE.COMPANY") }}
          </div>
          <div class="w-50 text-right text-muted mt-2">
            {{ company.commercial_name }}
          </div>
        </div>

        <v-divider />
        <!-- CHAT -->
        <div>
          <strong class="mt-5">
            <h4>
              CHAT
            </h4>
          </strong>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["currentUser", "campus", "company"]),
    picture() {
      if (
        this.currentUser.user.photo !== null &&
        typeof this.currentUser.user.photo !== "undefined"
      ) {
        return `${this.currentUser.user.photo.url}?h=100&w=100&fit=crop`;
      } else {
        return `${process.env.VUE_APP_CDN_PATH}images/default/blank.png`;
      }
    },
  },
};
</script>
