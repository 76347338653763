<template>
  <div>
    <b-row>
      <!-- PANEL 1 -->
      <b-col lg="4" md="4" sm="12">
        <v-row gutters="2" class="pt-0 mt-0 pb-0 mb-0">
          <!-- PANEL NOTIFICATIONS -->
          <b-col lg="12" md="12" sm="12" class="pt-0">
            <div class="card card-custom card-stretch gutter-b w-100">
              <div class="card-body">
                <strong class="mt-5">
                  <h4>
                    Notifications
                  </h4>
                </strong>
              </div>
            </div>
          </b-col>
          <!-- PANEL 3 PROFILE -->
          <b-col lg="12" md="12" sm="12">
            <Profile />
          </b-col>
        </v-row>
      </b-col>

      <b-col lg="8" md="8" sm="12">
        <v-row gutters="2" class="pt-0 mt-0 pb-0 mb-0">
          <!-- PANEL 4 -->
          <b-col
            lg="12"
            md="12"
            sm="12"
            class="pt-0"
            v-if="infoCampus && superUser"
          >
            <Applications />
          </b-col>

          <!-- PANEL 5 -->
          <b-col lg="12" md="12" sm="12" class="pt-0" v-if="infoCampus">
            <Promotions />
          </b-col>

          <!-- PANEL 6 -->
          <b-col lg="12" md="12" sm="12" class="pt-0">
            <Charges />
          </b-col>
        </v-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Profile from "@/view/content/components/home/Profile";
import Applications from "@/view/content/components/home/Applications";
import Promotions from "@/view/content/components/home/Promotions";
import Charges from "@/view/content/components/home/Charges";

import { mapGetters } from "vuex";

const SUPERADMIN = 1;

export default {
  components: {
    Profile,
    Applications,
    Promotions,
    Charges,
  },
  computed: {
    ...mapGetters(["campus", "currentUser"]),
    infoCampus() {
      return this.campus.campusId;
    },
    superUser() {
      return this.currentUser.roleId === SUPERADMIN;
    },
  },
};
</script>
