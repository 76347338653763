<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{ $t("HOME.CHARGES.TITLE") }}
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ $t("HOME.CHARGES.TITLE2") }}
        </span>
      </h3>
    </div>

    <!-- CONTENT -->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="min-width: 200px"></th>
              <th class="p-0" style="min-width: 100px"></th>
              <th class="p-0" style="min-width: 125px"></th>
              <th class="p-0" style="min-width: 150px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in list">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.id }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ $t("HOME.CHARGES.ID") }}
                  </span>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.name }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ $t("HOME.CHARGES.CLIENT") }}
                  </span>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.amount }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ $t("HOME.CHARGES.AMOUNT") }}
                  </span>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.payday }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ $t("HOME.CHARGES.PAYDAY") }}
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          name: "Test 1",
          amount: 0,
          payday: "MM/DD/YYYY",
        },
        {
          id: 2,
          name: "Test 2",
          amount: 0,
          payday: "MM/DD/YYYY",
        },
        {
          id: 3,
          name: "Test 3",
          amount: 0,
          payday: "MM/DD/YYYY",
        },
        {
          id: 4,
          name: "Test 4",
          amount: 0,
          payday: "MM/DD/YYYY",
        },
        {
          id: 5,
          name: "Test 5",
          amount: 0,
          payday: "MM/DD/YYYY",
        },
      ],
    };
  },
};
</script>
