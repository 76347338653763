<template>
  <div v-if="currentUser.roleId != '1' || currentUser.user.companyId">
    <StyleQuickStart v-if="quickStart" />
    <StyleWithoutQuickStart v-else />
  </div>
  <!-- Super admin dashboard (NOT DEFINED) -->
  <div v-else></div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import StyleQuickStart from "@/view/content/components/home/StyleQuickStart";
import StyleWithoutQuickStart from "@/view/content/components/home/StyleWithoutQuickStart";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      quickStart: true,
    };
  },
  name: "home",
  components: {
    StyleQuickStart,
    StyleWithoutQuickStart,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Home" }]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
