<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{ $t("HOME.PROMOTIONS.TITLE") }}
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ $t("HOME.PROMOTIONS.TITLE2") }}
        </span>
      </h3>
    </div>

    <!-- CONTENT -->
    <div class="card-body pt-3 pb-0 max-height-725">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="min-width: 200px"></th>
              <th class="p-0" style="min-width: 100px"></th>
              <th class="p-0" style="min-width: 125px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in promotions">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    v-if="!item.name"
                  >
                    {{ $t("HOME.PROMOTIONS.NO_NAME") }}
                  </span>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    v-else
                  >
                    {{ item.name }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ $t("HOME.PROMOTIONS.NAME") }}
                  </span>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.created_at | moment(campus.date_format) }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ $t("HOME.PROMOTIONS.CREATE_DATE") }}
                  </span>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.beginBookingExpiry | moment(campus.date_format) }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ $t("HOME.PROMOTIONS.INIT_DATE") }}
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  watch: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getPromotions"]),
  },
  created() {
    const status = "ACTIVE";
    this.getPromotions({ params: { status } });
  },
  computed: {
    ...mapGetters(["promotions", "campus"]),
  },
};
</script>

<style lang="scss">
.max-height-725 {
  max-height: 537px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 0.42rem !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e4e6ef;
  opacity: 1;
  border-radius: 0.42rem !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
