<template>
  <v-expansion-panel>
    <v-expansion-panel-header expand-icon="mdi-menu-down">
      <div class="text-left icon-muted" :class="{ 'icon-success': check }">
        <font-awesome-icon icon="check-circle" class="mr-2" />
        <h5 class="d-inline-block mb-0">
          {{ title }}
        </h5>
        <v-chip
          color="red"
          text-color="white"
          v-if="!check && !isSkip && !comments"
        >
          {{ $t("HOME.QUICKSTART.REQUIRED") }}
        </v-chip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      {{ description }}
      <b-row>
        <v-btn
          depressed
          color="grey"
          class="mt-3 ml-3"
          v-on:click="$emit('skip')"
          v-if="isSkip"
        >
          {{ $t("HOME.QUICKSTART.BTN_SKIP") }}
        </v-btn>
        <router-link
          :to="{
            path: `/${path}`,
          }"
        >
          <v-btn depressed color="primary" class="button-panel mt-3 ml-3">
            {{ textBtn }}
          </v-btn>
        </router-link>
      </b-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Title",
    },
    description: {
      type: String,
      default: "Description",
    },
    check: {
      type: Boolean,
      default: false,
    },
    isSkip: {
      type: Boolean,
      default: true,
    },
    textBtn: {
      type: String,
      default: "Button Text",
    },
    path: {
      type: String,
      default: "/home",
    },
    comments: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
